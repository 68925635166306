import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { joiResolver } from "@hookform/resolvers/joi"
import { Icon, IconLink, Link, LinkButton, Submit } from "@ioxio-priv/dataspace-ui"
import { useMediaQuery } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"

import { FormBody, HorizontalLine } from "@/commonStyles"
import AccessControlMode from "@/components/AccessControlMode"
import { SCHEMA } from "@/components/CreateDataSourceForm/validation"
import Form from "@/components/Form"
import FormInput from "@/components/FormInput"
import FormSelect from "@/components/FormSelect"
import SourceVisibility from "@/components/SourceVisibility"
import { labels } from "@/constants/labels"
import routes from "@/constants/routes"
import InitialLoading from "@/containers/InitialLoading"
import { Icons } from "@/dsIcon"
import useMyGroups from "@/hooks/useMyGroups"
import dataSourceAPI from "@/services/dataSourceAPI"
import { config } from "@/settings"
import { isValidHttpsUrl, sortByNameAndVersion } from "@/utilities"
import { toastError } from "@/utilities/errors"

export default function CreateDataSourceForm({
  asyncOnSubmit = async () => ({ ok: true }),
}) {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const [source, setSource] = useState()
  const { myGroups, loading: loadingMyGroups } = useMyGroups()
  const [definitionOptions, setDefinitionOptions] = useState([])
  const [loadingDefinitions, setLoadingDefinitions] = useState(false)

  useEffect(async () => {
    setLoadingDefinitions(true)
    const { data, ok } = await dataSourceAPI.getDataSourceDefinitions()
    if (ok) {
      const definitions = data.definitions.map((df) => {
        return {
          definition: df.version ? `${df.definition}_v${df.version}` : df.definition,
          deprecated: df.deprecated,
        }
      })
      setDefinitionOptions(
        sortByNameAndVersion(definitions).map((definition) => {
          return {
            value: definition.definition,
            icon: definition.deprecated ? (
              <DeprecatedIconWrapper
                data-testid={`${definition.definition}-deprecated`}
                title="Deprecated"
              >
                <Icon name="circle-dashed-x" />
              </DeprecatedIconWrapper>
            ) : null,
          }
        })
      )
      setLoadingDefinitions(false)
    }
  }, [])
  const form = useForm({
    mode: "onSubmit",
    resolver: joiResolver(SCHEMA),
    defaultValues: {
      state: "private",
      accessControlMode: config.requireVerifiedApiTokens
        ? "dataspace_verified"
        : "none",
    },
  })

  const [variant, group, baseUrl, definition] = form.watch([
    "variant",
    "group",
    "baseUrl",
    "definition",
  ])

  useEffect(() => {
    setSource(variant && group ? `${group}:${variant}` : group)
  }, [group, variant])

  async function onSubmit(evt) {
    evt.preventDefault()
  }

  async function handleBackendErrors(errors) {
    // `errors` is either a string or an array with FastAPI validation errors
    if (!Array.isArray(errors)) {
      return toastError("Failed to create data source", errors)
    }
    for (let error of errors) {
      const { loc, msg } = error
      const [, field] = loc || []
      form.setError(field, { type: "server", message: msg })
    }
  }

  async function _asyncOnSubmit() {
    const isFormValid = await form.trigger()
    if (!isFormValid) {
      return {
        ok: false,
      }
    }
    const data = form.getValues()

    const payload = {
      source: source,
      sourceUrl: data.baseUrl,
      state: data.state,
      accessControlMode: data.accessControlMode,
      definition: data.definition,
      description: data.description,
    }
    return asyncOnSubmit(payload, handleBackendErrors)
  }

  if (loadingMyGroups || loadingDefinitions) {
    return <InitialLoading />
  }

  return (
    <Wrapper>
      <Form
        onSubmit={onSubmit}
        rightButtons={
          <>
            {smallScreen ? (
              <>
                <IconLink
                  href={routes.MY_SOURCES}
                  variant={"outlined"}
                  icon={Icons.cancel}
                />
                <Submit
                  icon={Icons.success}
                  iconOnly
                  label={"Create"}
                  color="success"
                  asyncOnClick={{
                    asyncFn: _asyncOnSubmit,
                  }}
                />
              </>
            ) : (
              <>
                <LinkButton
                  variant={"outlined"}
                  icon={Icons.cancel}
                  href={routes.MY_SOURCES}
                >
                  Cancel
                </LinkButton>
                <Submit
                  icon={Icons.success}
                  color="success"
                  asyncOnClick={{
                    asyncFn: _asyncOnSubmit,
                  }}
                >
                  Create
                </Submit>
              </>
            )}
          </>
        }
      >
        <FormBody>
          <FormSelect
            baseProps={{
              "data-testid": "definition",
            }}
            label={labels.dataSource.fields.dataProduct.label}
            name={"definition"}
            options={definitionOptions}
            form={form}
            required
            divider
            placeholder={"Data Product"}
            closingItem={
              <SendRequestMessage>
                <span>Can’t see what you’re looking for?</span> Send us a reqest on the{" "}
                <Link href="https://slack.ioxio.com/" target="_blank">
                  IOXIO Community Slack
                </Link>
              </SendRequestMessage>
            }
            tooltipText={labels.dataSource.fields.dataProduct.tooltipText}
          >
            <>
              Which data product is your source implementing? View definitions or
              propose new ones at{" "}
              <Link target="_blank" rel="noreferrer" href={config.sourcesRepo}>
                {config.sourcesRepo}
              </Link>
            </>
          </FormSelect>
          <FormSelect
            label={labels.dataSource.fields.group.label}
            name={"group"}
            divider
            baseProps={{ "data-testid": "group" }}
            options={myGroups.map((item) => item.group)}
            form={form}
            showAddGroupLink
            required={true}
            placeholder={"Select group"}
            tooltipText={labels.dataSource.fields.group.tooltipText}
          />
        </FormBody>
        <FormBody>
          <FormInput
            label={labels.dataSource.fields.variant.label}
            name={"variant"}
            form={form}
            placeholder={"Additional name"}
            tooltipText={labels.dataSource.fields.variant.tooltipText}
            autoCapitalize={"off"}
            baseProps={{
              "data-testid": "variant",
            }}
          />
          <FormInput
            label={labels.dataSource.fields.description.label}
            name={"description"}
            multiline
            minRows={3}
            form={form}
            maxSymbols={1000}
            placeholder={"Add a description"}
            tooltipText={labels.dataSource.fields.description.tooltipText}
          />
        </FormBody>
        <HorizontalLine />
        <FormBody>
          <FormInput
            required
            label={labels.dataSource.fields.baseUrl.label}
            name={"baseUrl"}
            form={form}
            placeholder={"https://"}
            tooltipText={labels.dataSource.fields.baseUrl.tooltipText}
            type={"url"}
            description={
              <>
                The base URL for your productized data source. Must use HTTPS.{" "}
                {isValidHttpsUrl(baseUrl) && definition ? (
                  <>
                    {" "}
                    Requests will be sent to{" "}
                    <Highlight>
                      {baseUrl.replace(/\/+$/, "")}/{definition}
                    </Highlight>
                  </>
                ) : (
                  ""
                )}
              </>
            }
          />
          <SourceVisibility source={source} form={form} />
        </FormBody>
        <HorizontalLine />
        <FormBody>
          <AccessControlMode form={form} />
        </FormBody>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`
const DeprecatedIconWrapper = styled("div")`
  background-color: ${(p) => p.theme.palette.warning.light};
  border-radius: 25px;
  padding: 0px 5px;
  svg {
    display: block;
  }
`

const SendRequestMessage = styled("div")`
  padding-top: 0.5rem;
  text-align: center;
  > span {
    font-weight: 500;
  }
`

const Highlight = styled("span")`
  font-style: italic;
`
