import React from "react"

import { theme2 as theme } from "@ioxio-priv/dataspace-ui"
import { ReactComponent as DataspaceLogo } from "@ioxio-priv/dataspace-ui/src/assets/theme2logo.svg"

import { baseConfig, baseDsConfig, runtimeSettings } from "./base"

export { runtimeSettings as rs }

// Dataspace specific configuration
export const dsConfig = {
  ...baseDsConfig,
  dataspaceVariant: "Data Finland",
  dataspaceLogo: <DataspaceLogo />,
  footerExternalLinks: [
    { name: "IOXIO", url: "https://ioxio.com/" },
    { name: "GitHub", url: "https://github.com/DataFinland" },
  ],
}

// Application specific configuration
export const config = {
  ...baseConfig,
  theme: theme,

  sourcesRepo: "https://github.com/DataFinland/definitions",
  definitions: "https://github.com/DataFinland/definitions/tree/main/DataProducts",
  termsOfService: "https://datafinland.com/terms-of-service",
  privacyPolicy: "https://datafinland.com/privacy",
  externalAppManagement: true,
  externalAppSettings: {
    name: "Sinuna",
    contact: "support@sinuna.fi",
    registrationURL: "https://developer.sinuna.fi/getting_access",
  },
  developerResources: {
    title: "Developer resources",
    description: "Resources on how to build, integrate and innovate on Dataspaces.",
    sections: [
      {
        title: "Guides",
        description:
          "Tutorials on how to build on and integrate using our Dataspace technology.",
        icon: "basic-guides",
        links: [
          {
            label: "How to create a group",
            href: "https://docs.ioxio.dev/guides/managing-groups/",
          },
          {
            label: "How to build an application",
            href: "https://docs.ioxio.dev/guides/building-an-application/",
          },
          {
            label: "How to create data definitions",
            href: "https://docs.ioxio.dev/guides/building-a-data-definition/",
          },
          {
            label: "How to build a data source",
            href: "https://docs.ioxio.dev/guides/building-a-data-source/",
          },
          {
            label: "Verify id_token in a data source",
            href: "https://docs.ioxio.dev/guides/verifying-id-token/",
          },
          {
            label: "Verifying API tokens",
            href: "https://ioxio.notion.site/Verifying-API-tokens-1528e3ae865b804c9f2dd354b143c743",
          },
        ],
      },
      {
        title: "APIs",
        icon: "basic-guides",
        description:
          "We offer API docs in multiple formats to ensure they are readable for anyone, no matter their background.",
        links: [
          {
            label: "Product gateway - Swagger UI",
            href: "https://gateway.datafinland.com/docs",
          },
          {
            label: "Developer portal - Swagger UI",
            href: "https://developer.datafinland.com/docs",
          },
          {
            label: "Data Finland data definitions viewer",
            href: "https://definitions.datafinland.com/",
          },
        ],
      },
      {
        title: "Video guides",
        icon: "video-guides",
        description:
          "If you prefer to learn by watching and listening check out our videos below.",
        links: [
          {
            label: "Defining data products",
            href: "https://www.youtube.com/watch?v=yPzN04ICsbw&t=194s",
          },
          {
            label: "Productizing data",
            href: "https://www.youtube.com/watch?v=f-f6P_-8zoQ",
          },
        ],
      },
      {
        title: "Other available resources",
        icon: "other-guides",
        description: "Links to other resources that might come handy.",
        links: [
          {
            label: "Data Finland GitHub",
            href: "https://github.com/DataFinland",
          },
          {
            label: "IOXIO Dataspace GitHub",
            href: "https://github.com/ioxio-dataspace",
          },
          {
            label: "Party configuration schema",
            href: "https://docs.ioxio.dev/schemas/party-configuration/",
          },
          {
            label: "Dataspace configuration schema",
            href: "https://docs.ioxio.dev/schemas/dataspace-configuration/",
          },
          {
            label: "IOXIO Tags™",
            href: "https://docs.ioxio.dev/tags/",
          },
        ],
      },
      {
        title: "Examples",
        icon: "other-guides",
        description: "We offer code examples to make it easier to get started.",
        links: [
          {
            label: "Example productizer",
            href: "https://github.com/ioxio-dataspace/example-productizer",
          },
          {
            label: "Example app",
            href: "https://github.com/ioxio-dataspace/example-app",
          },
        ],
      },
    ],
  },
  // Don't update these as they are the VF variants
  guideDataSources: "https://miro.com/app/board/uXjVOBHtQdI=/",
  guideApplications: "https://miro.com/app/board/uXjVOBHtQcU=/",
}
